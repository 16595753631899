<template><div>

<img src="./i/ncrpc-logo-narrow.jpg" />
<hr/>
<p>New Creation Reformed Presbyterian Church is a Christian congregation with a full commitment to the authority of the Bible as the inspired, inerrant, and infallible Word of
  God. We are also committed to the historic Christian faith as expressed in the classic creeds and confessions of the Reformation.</p>
<p>We believe that the acceptable way of worshipping God is instituted by God Himself in the Bible and that what we do in worship on the Lord's Day
  should be limited to what He has revealed. Our worship is focused on prayer, the reading and preaching of the Word of God, the celebration of the
  Lord's Supper, and the giving of tithes and offerings. Our musical praise employs only the divinely inspired Book of Psalms of the Bible without the
  aid of musical instruments.</p>
<p>We are located in Kitchener, Ontario. Come and join us for worship.</p>

</div></template>
<script>
export default {
  name: 'Index',
}
</script>
<style lang="scss" scoped>
  img { width: 100%; }
  hr { border-top: 6px double #080; }
</style>
