<template><div>

  <h1>Times &amp; Location</h1>

  <h4>Sunday worship services:</h4>
      <ul class="list">
          <li>First Service: 9:00 a.m.</li>
          <li>Evening Service: 4:00 p.m.</li>
      </ul>

  <h4>Location:</h4>
      <ul class="list">
          <li>Holy Trinity Anglican Church</li>
          <li>237 Wilson Avenue</li>
          <li>Kitchener, ON</li>
		  <li>N2C 1G9</li>
          <li>(<span class="emphasis">Note: The meeting location is not the church mailing address.</span>)</li>
      </ul>
  <div class="map">
      <iframe src="https://www.google.com/maps/embed/v1/place?q=Holy%20Trinity%20Anglican%20Church%2C%20237%20Wilson%20Avenue%2C%20Kitchener%2C%20ON&key=AIzaSyDbkmWTsae5NDMHALRUEQ9SK7BqMjK1-Lk"></iframe>
  </div>

</div></template>
<script>
export default {
  name: 'Location',
}
</script>
<style lang="scss">

ul li {
    list-style-type: none;
}

.map {
    padding: 0 5%;
    margin: 4rem auto;
    height: 25rem;

    iframe {
        -webkit-box-shadow: 1px 1px 7px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow:    1px 1px 7px 0px rgba(50, 50, 50, 0.75);
        box-shadow:         1px 1px 7px 0px rgba(50, 50, 50, 0.75);
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
}

</style>
