<template><div>

<iframe tabindex="-1" width="1" height="840" src="https://embed.sermonaudio.com/browser/broadcaster/newcreationrpc/?sort=newest&page_size=10&scrolls=false&style=compact&filters=false&rounded=true" style="min-width: 100%; max-width: 100%; " allow="autoplay" frameborder="0" scrolling="no"></iframe>

</div></template>
<script>
export default {
  name: 'Sermons',
}
</script>
<style lang="scss"></style>
