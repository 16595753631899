<template><div>

  <h1>Contact Us</h1>

  <h4>Pastor:</h4>
  <ul class="list">
      <li>Scott Wilkinson</li>
      <li>Phone: 519-807-4134</li>
      <li>email: <a href="mailto:pastorscottwilkinson@gmail.com">pastorscottwilkinson@gmail.com</a></li>
  </ul>

  <h4>Elder:</h4>
  <ul class="list">
      <li>Rob Somers</li>
      <li>Phone: 519-622-5713</li>
      <li>email: <a href="mailto:uberkermit@gmail.com">uberkermit@gmail.com</a></li>
  </ul>

  <h4>Facebook:</h4>
  <ul class="list">
  	<li><a href="https://www.facebook.com/newcreationrpc">https://www.facebook.com/newcreationrpc</a></li>
  </ul>

  <h4>Mailing Address:</h4>
  <ul class="list">
      <li>1075 St. Charles Street W</li>
      <li>Bloomingdale, Ontario</li>
      <li>N0B 1K0</li>
      <li>(<span class="emphasis">Note: The church mailing address is not the meeting location.</span>)</li>
  </ul>

</div></template>
<script>
export default {
  name: 'Contact',
}
</script>
<style lang="scss"></style>
