<template><div class="leadership">
  <h1>Leadership</h1>
  <h4>Teaching Elder</h4>
  <p><img :src="Wilkinson" width="200" height="205" />
  From its earliest days, Rev. Scott Wilkinson has been pastor at New Creation RPC. Previously, he was pastor of the Walton Reformed Presbyterian Church in New York from 1995-2001.
  Rev. Wilkinson received his B.A. from Arizona State University in 1992,
  and his M.Div. from <a href="http://www.rpts.edu">Reformed Presbyterian Theological Seminary</a> in 1995. He is married to Elineke, and has six children.
  <br style="clear: both;" /></p>
  <h4>Ruling Elders</h4>
  <p><img :src="Lemos" width="200" height="275" />
  Seyr Lemos is a software engineer, originally from the Presbyterian church in Brazil. He attended the Presbyterian Seminary in Brasilia from 2010 to 2012. Seyr is married to Emilia and has one daughter.
  <br style="clear: both;" /></p>
  <p><img :src="Smid" width="200" height="273" />
  John Smid is a mechanical engineer, which has allowed him to work in the United States and Canada. John and his wife Laura, and their four daughters, were members of the Reformed Presbyterian Church in Pennsylvania before moving back to the Kitchener area.
  <br style="clear: both;" /></p>
  <p><img :src="Somers" width="200" height="225" />
  Rob Somers makes his living as a carpenter, and considers himself to be in very good company (Matthew 13:55; Mark 6:3). He is married to Rachael, and has one daughter.
  <br style="clear: both;" /></p>

</div></template>
<script>

import Lemos from './i/lemos.jpg'
import Smid from './i/smid.jpg'
import Somers from './i/somers.jpg'
import Wilkinson from './i/wilkinson-small.jpg'

export default {
  name: 'Leadership',
  data() {
    return {
      Lemos,
      Smid,
      Somers,
      Wilkinson,
    }
  },
}
</script>
<style lang="scss">
.leadership {
  h4 {
    font-size: 1.3rem;
  	font-weight: 700;
  	color: #414141;
  }
  img {
    display: block;
    float: left; margin: 0px 15px 15px 0px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
}
@media (max-width: 400px) {
  .leadership {
    img {
      float: none;
    }
  }
}
</style>
