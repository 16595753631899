import axios from 'axios'
let cookies = require('browser-cookies')

let p = null
let db = []

db.fetch = function() {
  if (!p) {
    db.status = 'loading'
    p = axios.get('https://newcreationrpc.org/db')
      .then(r => {
        // Splice data from o into db.
        db.splice(0, db.length, ...r.data.filter(p => ((p.published || p.ncrpc_published) && !p.copyright)))
        db.status = 'loaded'
        // No promise active any longer.
        p = null
      })
      .catch(e => {
        // No promise active any longer.
        p = null
        // Flag the error.
        db.status = 'failed'
        throw e
      })
  }
  return p
}
db.fetch()

let player = {
  track: 0,
  part: 'normal',
  expand: false,
  position: 61,
  duration: 125,
  seekPosition: 61,
  playRequested: false,
  height: 0,
  loop: {
    type: 'all',
    voices: ['normal'],
  },
}

let user = {
  id: null,
  first_name: null,
  last_name: null,
  username: null,
  p: null,
  fetchRetry: false,
  errorMessage: null,
  get isAnonymous() { return this.id === null },
  get isBusy() { return this.p !== null }
}

function consumeUserData(o, logout) {
  if (o && o.id) {
    user.id = o.id
    user.username = o.username
    user.last_name = o.last_name
    user.first_name = o.first_name
    user.status = 'logged in'
    localStorage.setItem('user', JSON.stringify(o))
  } else if (logout) {
    user.id = null
    user.first_name = null
    user.last_name = null
    user.username = null
    user.status = 'anonymous'
    localStorage.removeItem('user')
  }
}

consumeUserData(JSON.parse(localStorage.getItem('user') || 'null'))

user.fetch = () => {
  if (user.isBusy)
    return Promise.reject('busy')
  user.fetchRetry = false
  user.errorMessage = null
  user.status = 'fetching'
  user.p = axios.get('/user')
    .then(r => {
      consumeUserData(r.data)
      // No promise active any longer.
      user.p = null
    })
    .catch(e => {
      // No promise active any longer.
      user.p = null
      // Flag the error.
      user.status = 'failed'
      user.errorMessage = 'A failure occurred.'
      user.fetchRetry = true
      throw e
    })
  return user.p
}
user.login = (username, password) => {
  if (user.isBusy)
    return Promise.reject('busy')
  user.lastAction = 'login'
  user.errorMessage = null
  user.fetchRetry = false
  user.status = 'logging in'
  let data = new FormData()
  data.append('login', 'login')
  data.append('username', username)
  data.append('password', password)
  user.p = axios.post('/user', data, {
    headers: {
      'X-CSRFToken': cookies.get('csrftoken') || '',
    },
  }).then(r => {
    consumeUserData(r.data)
    // No promise active any longer.
    user.p = null
  }).catch(e => {
    // No promise active any longer.
    user.p = null
    // Flag the error.
    user.status = 'failed'
    user.errorMessage = 'Login failed. Please try again later.'
    throw e
  })
}
user.logout = () => {
  if (user.isBusy)
    return Promise.reject('busy')
  user.lastAction = 'logout'
  user.errorMessage = null
  user.fetchRetry = false
  user.status = 'loggin out'
  let data = new FormData()
  data.append('logout', 'logout')
  user.p = axios.post('/user', data, {
    headers: {
      'X-CSRFToken': cookies.get('csrftoken') || '',
    },
  }).then(r => {
    consumeUserData(r.data, true)
    // No promise active any longer.
    user.p = null
  }).catch(e => {
    // No promise active any longer.
    user.p = null
    // Flag the error.
    user.status = 'failed'
    user.errorMessage = 'Logout failed. Please try again later.'
    throw e
  })
}
user.fetch()

global.db = db
global.player = player
global.user = user

export { db, player, user }
