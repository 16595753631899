<template><div>

  <h1>Links</h1>

  <h4>Canadian Reformed Presbyterian Congregations:</h4>
  <ul class="list">
      <li>Almonte, ON <a href="http://hillsiderpc.ca" target="_blank">Hillside Reformed Presbyterian Church</a></li>
      <li>Edmonton, AB <a href="http://shelterrpchurch.com" target="_blank">The Shelter Reformed Presbyterian Church</a></li>
      <li>Hudson, QC <a href="http://www.rpcmontreal.org" target="_blank">Hudson St. Lazare Reformed Presbyterian Church</a></li>
      <li>Ottawa, ON <a href="http://www.rpcottawa.org" target="_blank">Ottawa Reformed Presbyterian Church</a></li>
      <li>Russell, ON <a href="http://russellrpc.org" target="_blank">Russell Reformed Presbyterian Church</a></li>
  	<li>Toronto, ON <a href="http://www.epctoronto.org" target="_blank">Evangelical Presbyterian Church</a></li>
  </ul>

  <h4>Local reformed &amp; presbyterian churches fraternally connected to New Creation:</h4>
  <ul class="list">
      <li>Brantford, ON <a href="http://www.livingwaterreformedchurch.com" target="_blank">Living Water URC</a></li>
      <li>Cambridge, ON <a href="http://riversidepres.com" target="_blank">Riverside ARP</a></li>
      <li>Cambridge, ON <a href="http://wrrrc.org" target="_blank">West River Road Reformed Church</a></li>
      <li>Sheffield, ON <a href="http://www.gcc-opc.org" target="_blank">Grace Covenant Church</a></li>
      <li>Sheffield, ON <a href="http://zurch.ca" target="_blank">Zion URC</a></li>
      <li>Woodstock, ON <a href="http://www.gracepresbyterian.ca" target="_blank">Grace Presbyterian Church</a></li>
  </ul>

  <h4>Points of interest:</h4>
  <ul class="bottom">
      <li><a href="http://reformedpresbyterian.org/">The Reformed Presbyterian Church of North America</a></li>
      <li><a href="http://www.crownandcovenant.com/default.asp">Crown and Covenant Publications</a></li>
      <li><a href="http://www.gillespieacademy.ca/">Gillespie Academy</a></li>
  </ul>

</div></template>
<script>
export default {
  name: 'Links',
}
</script>
<style lang="scss" scoped>

ul li {
    list-style-type: none;
    margin: 0.7rem 0;
}
ul {
    margin: 0;
}

</style>
