<template><div>

  <h1>Our Beliefs</h1>
  <p>We believe that the actual written words of the Bible are the very words of God. We believe that God has given us the Scriptures of the Old and New
  Testaments as the all-sufficient and only rule of faith and practice, and judge of controversies.</p>
  <blockquote>A false minister may say, "You have no right to use your
  private judgment: leave the Bible to us who are ordained." A true minister will say "Search the Scriptures, and if I do not teach you what is Scriptural, do
  not believe me." A false minister may cry, "Hear the Church," and "Hear me." A true minister will say, "Hear the Word of God." -J.C. Ryle</blockquote>

  <p>We also believe that the Scriptures teach a system of doctrine that can and should be stated in a clear systematic manner; a "creed" or "confession."
  Christianity has always been a "confessional" religion in that it has always been theological. A confession is simply the Church's understanding of what the
  Bible teaches. To have no creed but the Bible only works when there is agreement as to what the Bible teaches.</p>

  <p>New Creation accepts as our creed The Westminster Confession of Faith and the Larger and Shorter Catechisms. These are found in <em>The Constitution of
  the Reformed Presbyterian Church of Canada</em>. Click <a href="http://rpccanada.org/constitution/">here</a> to read it.</p>

  <p>Some of the important doctrines we confess the Scriptures teach include:</p>

  <div class="beliefs">
  <p>There is one living and true God, Who is infinite, eternal, and unchangeable, in His being, wisdom, power, holiness, justice, goodness, and truth; and that the
  Scriptures reveal that there are three persons in the Godhead, the Father, the Son, and the Holy Spirit; and these three are one true eternal God, the same in
  substance, equal in power and glory.</p>

  <p>All of life is to be lived to the glory of God. God is sovereign and rules over every aspect of life.</p>

  <p>Everyone is born sinful, everyone sins against God and so everyone deserves His judgement in Hell.</p>

  <p>One is saved by Jesus Christ's work alone. There is nothing about anyone that wins the favor of God. No one can earn the forgiveness of sins by doing good deeds.</p>

  <p>The means by which a person receives the forgiveness of sin and eternal life is by faith alone. We are saved only by confidence and personal trust in Jesus
  Christ as He is offered and revealed to us in the Bible.</p>

  <p>We are saved in order to do good works. A faith that does not evidence itself through a loving, humble, and obedient walk with Christ is not a true saving faith.</p>

  <p>Our Lord Jesus Christ has commissioned His church to spread the good news of salvation to all people, that they too would receive His forgiveness, serve Him,
  and enjoy eternal life forever.</p>
  </div>

  <p>We realize that there are many Christians who have a high view of the Bible, but do not believe one can be certain about the length of the days of creation. We
  believe, as the Westminster Confession says, that the universe was supernaturally created over the time span of six sequential days of ordinary length; that it
  was not created by natural laws over a period of billions of years. We also believe that any view which accepts that there was any form of the curse (including
  animal death), prior to the sin of our first parents is a serious error.</p>

</div></template>
<script>
export default {
  name: 'Beliefs',
}
</script>
<style lang="scss">

blockquote {
    background: #F9F9F9;
    border-left: 10px solid #CCCCCC;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
    color: #CCCCCC;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

.beliefs {
    margin-left: 4%;
    margin-right: 5%;
}

</style>
