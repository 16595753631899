<template><svg class="mi" :style="style"><use :xlink:href="`#${id}`"></use></svg></template>
<script>
export default {
  name: 'mi',
  props: {
    size: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    style() {
      if (this.size) {
        return {
          width: this.size,
          height: this.size,
        }
      }
      return {}
    },
  },
}
</script>
<style lang="scss">
  .mi { width: 24px; height: 24px; }
</style>
