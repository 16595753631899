<template><div id="app" ref="app" @click="click" @mousedown="mousedown">
  <div class="main">
    <div class="sidebar left" v-if="reveal1" :class="{reveal:reveal2}">
      <label><mi size="24px" id="music_note" />Now Playing</label>
      <track-info />
      <router-link to="/psalms"><mi size="24px" id="list_alt" />See playlist</router-link>
    </div>
    <div class="sidebar right" v-if="reveal1" :class="{reveal:reveal2}">
      <label><mi size="24px" id="loop" />Loop Settings</label>
      <loop-options :title="false" />
    </div>
    <div class="title">
      <span class="nc">New Creation</span>
      <span class="rpc">Reformed Presbyterian Church</span>
	  <a class="fb" href="https://www.facebook.com/newcreationrpc">
	  	<img :src="Facebook" width="30" height="30" /></a>
    </div>
    <div class="navbar" :class="{forceShow:forceShow}">
      <template v-for="r in routes">
        <router-link v-if="r.navbar !== false" :key="`link${r.component.name}`" exact active-class="active" exact-active-class="" :to="r.path" v-html="r.name || r.component.name" />
      </template>
      <router-link class="account" :class="{anonymous:user.isAnonymous}" exact active-class="active" exact-active-class="" to="/account">
        <mi size="36px" id="account_box" />
        {{ user.isAnonymous ? 'Login' : `Hi, ${user.first_name}` }}
      </router-link>
      <a href="#" class="hamburger" v-if="!forceShow" @click="forceShow = true">
        <mi size="36px" id="menu" /> Menu
      </a>
    </div>
    <div class="content">
      <router-view />
    </div>
    <div :style="{height:player.height+'px'}" />
  </div>
  <player />
</div></template>

<script>
import { GA_ID } from './tracking'
import { player, user } from './db.js'
import { routes } from './router'
import Player from './Player'
import TrackInfo from './TrackInfo'
import LoopOptions from './LoopOptions'
import mi from './mi.vue'
import Facebook from './i/fblogo60.png'
export default {
  name: 'app',
  data() {
    return {
      routes,
      player,
      user,
      reveal1: player.playRequested,
      reveal2: player.playRequested,
      hideTimeout: null,
      forceShow: false,
	  Facebook,
    }
  },
  components: {
    Player,
    TrackInfo,
    LoopOptions,
    mi,
  },
  methods: {
    outboundLink(action, href) {
      let o = {
        event_category: 'Outbound Link',
        event_label: href,
      }
      if (action === 'click') {
        o.transport_type = 'beacon'
        o.event_callback = function() { window.open(href, '_blank') }
      }
      if (window.gtag)
        gtag('event', action, o)
    },
    findLink(el) {
      while (el && (el.tagName !== 'A'))
        el = el.parentNode
      return el
    },
    click(e) {
      let el = this.findLink(e.target)
      if (!el || el.className.split().indexOf('hamburger') < 0) {
        this.forceShow = false
      }
      if (!el || !el.href || el.hostname === window.location.hostname)
        return // don't track internal links
      // Track out-bound click events!
      this.outboundLink('click', el.href)
      e.preventDefault() // event callback will trigger navigation
    },
    mousedown(e) {
      if (e.which === 3) {
        let el = this.findLink(e.target)
        if (!el || el.hostname === window.location.hostname)
          return // don't track internal links
        // Track out-bound right click events!
        this.outboundLink('click-right', el.href)
      }
    },
  },
  watch: {
    '$route.path': function(nv) {
      this.forceShow = false
      if (document.titles && nv in document.titles) {
        document.title = document.titles[nv]
      } else {
        document.title = 'New Creation RPC'
      }
      if (window.gtag)
        gtag('config', GA_ID, {'page_path': nv})
    },
    'player.playRequested': function(nv) {
      if (this.hideTimeout !== null) {
        clearTimeout(this.hideTimeout)
        this.hideTimeout = null
      }
      if (nv) {
        this.reveal1 = true
        this.$nextTick(() => { this.$refs.app.offsetHeight; this.reveal2 = true })
      } else {
        this.reveal2 = false
        this.hideTimeout = setTimeout(() => { this.reveal1 = false }, 500)
      }
    },
  },
}
</script>

<style lang="scss">

@import './scss/vars.scss';

@import url('https://fonts.googleapis.com/css?family=Alegreya+SC:700|PT+Serif:400,400i,700|Roboto+Slab:700|Roboto:400,700&display=swap');


  html, body {
    margin: 0;
    padding: 0;
    font-family: $serif;
    font-size: 19px;
  }
  input {
    font-size: 19px;
    font-family: $sans;
  }
  button {
    font-size: 19px;
    padding: 5px 10px;
  }
  #app {
    > .main {
      position: relative;

      > .title {
        position: relative;
        z-index: 1;
        background: #fff;
        cursor: default;
        padding: 4px .5em;
        text-align: center;
        .nc {
          display: inline-block;
          font-family: 'Roboto Slab', serif;
          color: #080;
          font-size: 22px;
          white-space: nowrap;
        }
        .rpc {
          font-family: 'Roboto', sans-serif;
          color: #668080;
          white-space: nowrap;
        }
		.fb {
			position: relative;
			padding-left: 5px;
			width: 30px;
			display: inline-block;
			height: 10px;
			margin-bottom: -6px;
			img {
				border: 0;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
      }

      > .navbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background: #252525;
        padding: 0 10px;
        font-family: $sans;
        box-shadow: 0 0 1rem 0 #000000;

        > a {
          padding: 0 .7em;
          text-decoration: none;
          line-height: 48px;
          color: #B1B5B8;
          transition: all 200ms ease-in-out;

          &:visited {
            color: #B1B5B8;
          }
          &:hover {
            color: #fff;
            background-color: #5E676E;
          }

          &.active {
            color: #EFF0F4;
            background-color: #3E474E;
          }
        }

        .hamburger {
          display: none;
          > svg {
            padding-top: 6px;
            vertical-align: top;
            fill: #999;
            transition: all 200ms ease-in-out;
          }
          &:hover > svg {
            fill: #fff;
          }
        }

        .account {
          > svg {
            padding-top: 6px;
            vertical-align: top;
            fill: #0c0;
            transition: all 200ms ease-in-out;
          }
          &:hover > svg {
            fill: #0f0;
          }
          &.active {
            > svg {
              fill: #0f0;
            }
            &.anonymous {
              svg {
                fill: #fa4;
              }
            }
          }

          &.anonymous {
            > svg {
              fill: #999;
            }
            &:hover > svg {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .hamburger {
      display: block !important;
    }
    .navbar > a:not(.hamburger) {
      display: none;
    }
    .navbar.forceShow > a {
      display: block;
    }
  }

  $w: 750px;
  $mw: 15px;
  $mh: 40px;

  .content {
    padding: 0;
    margin: $mh $mw;
    max-width: $w;
  }
  @media (min-width: $w + 2 * $mw) {
    .content {
    	width: calc(#{$w} - (100vw - 100%));
    	margin: $mh auto;
    }
  }

  .main > .sidebar {
    display: none;
    position: fixed;

    &.left { left: 20px; }
    &.right { right: 20px; }

    & > label, & > a {
      display: block;
      padding: 0 20px;
      font-style: italic;
      line-height: 40px;
    }

    & > label {
      .mi {
        vertical-align: sub;
        padding-right: 5px;
      }
      margin-bottom: -10px;
      background: #cfc;
      border-bottom: 2px solid #000;
    }

    & > a {
      .mi {
        vertical-align: sub;
        padding-top: 2px;
        padding-right: 5px;
        fill: #000;
      }
      margin-top: -10px;
      background: #ccc;
      border-top: 2px solid #000;
      color: #000;
      text-decoration: none;
    }

    bottom: $size + 2px + 20px;
    box-sizing: border-box;
    width: calc((100vw - #{$w}) / 2 - 60px);
    max-width: 950px;
    border: 2px solid #000;
    background: #fff;
    font-family: $sans;
    z-index: 2;
    box-shadow: 0 0 1rem 0 #000000;
    border-radius: 10px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    &.reveal {
      opacity: 1;
    }
  }

  @media (min-width: $sidebarThreshold) {
    .main > .sidebar { display: block; }
  }


  h1 {
  	font-size: 1.5rem;
  	font-weight: 700;
  	color: #414141;
  }

  a:link, a:active, a:visited {
    color: #3366FF;
  }
  a svg {
    fill: #3366FF;
  }

  .emphasis {
      color: #F20A30;
      font-weight: 700;
  }

</style>
