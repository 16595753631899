require('string.prototype.padstart').shim()

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import _0 from './mi/list_alt.svg'
import _1 from './mi/music_note.svg'
import _2 from './mi/loop.svg'
import _3 from './mi/play_arrow.svg'
import _4 from './mi/stop.svg'
import _5 from './mi/pause.svg'
import _6 from './mi/more_horiz.svg'
import _7 from './mi/check_box.svg'
import _8 from './mi/check_box_outline_blank.svg'
import _9 from './mi/radio_button_checked.svg'
import _10 from './mi/radio_button_unchecked.svg'
import _11 from './mi/people.svg'
import _12 from './mi/link.svg'
import _13 from './mi/account_box.svg'
import _14 from './i/bopfw.jpg'
import _15 from './mi/menu.svg'
import _16 from './mi/close.svg'
import _17 from './i/wilkinson-big.jpg'
import _18 from './i/ncrpc-logo-og.jpg'

new Vue({
  el: '#app',
  router,
  render: h => h(App),
  created() {
    let el = document.getElementById('splash')
    el.parentNode.removeChild(el)
  },
})
