<template><div class="outer" ref="outer">
  <audio ref="audio" crossorigin="use-credentials"
      @abort="aAbort"
      @canplay="aCanPlay"
      @canplaythrough="aCanPlayThrough"
      @durationchange="aDurationChange"
      @emptied="aEmptied"
      @ended="aEnded"
      @error="aError"
      @interruptbegin="aInterruptBegin"
      @interruptend="aInterruptEnd"
      @loadeddata="aLoadedData"
      @loadedmetadata="aLoadedMetadata"
      @loadstart="aLoadStart"
      @pause="aPause"
      @play="aPlay"
      @playing="aPlaying"
      @progress="aProgress"
      @ratechange="aRateChange"
      @seeked="aSeeked"
      @seeking="aSeeking"
      @stalled="aStalled"
      @suspend="aSuspend"
      @timeupdate="aTimeUpdate"
      @volumechange="aVolumeChange"
      @waiting="aWaiting">
		<source ref="source"
        type="application/x-mpegURL"
        crossorigin="use-credentials"
        @error="sError"
      />
	</audio>
  <div class="expand" v-if="player.expand">
    <track-info v-if="player.expand==='info'" :closeable="true" />
    <loop-options v-if="player.expand==='loop'" :closeable="true" />
  </div>
  <div class="player">
    <div title="Now Playing" class="b sidehide"
        :class="{selected:player.expand==='info'}"
        @click="player.expand=player.expand==='info'?false:'info'">
      <mi size="36px" id="music_note" />
      <div/>
    </div>
    <div title="Loop Settings" class="b sidehide"
        :class="{selected:player.expand==='loop'}"
        @click="player.expand=(player.expand==='loop')?false:'loop'">
      <mi size="36px" id="loop" />
      <div/>
    </div>
    <div title="Show Playlist" class="b sidehide"
        :class="{selected:!player.expand&&$route.path==='/psalms'}"
        @click="showPlaylist">
      <mi size="48px" id="list_alt" />
      <div/>
    </div>
    <div class="b" @click="play" v-if="!player.playRequested">
      <mi size="48px" id="play_arrow"/>
    </div>
    <div class="b" @click="pause" v-else-if="actuallyPlaying">
      <mi size="48px" id="pause" />
    </div>
    <div class="b" @click="reload" v-else>
      <mi size="48px" id="more_horiz" />
    </div>
    <div class="b" @click="stop">
      <mi size="48px" id="stop" />
    </div>
    <div class="s" @click="sliderClick" v-if="durationSupported">
      <div class="p" :style="{width:progress}" />
      <div class="t">
        {{ timeToString(player.position) }}
        / {{ timeToString(player.duration) }}
      </div>
    </div>
  </div>
</div></template>

<script>

import { db, player, user } from './db.js'
import Hls from 'hls.js/dist/hls.js'
import NoSleep from './NoSleep.js'

let noSleep = new NoSleep()
let timeout = null
const parts = ['normal','soprano','alto','tenor','bass','ncrpc']
const PSALM_NO = 'psalm_no'
const PSALM_LETTER = 'psalm_letter'
const PSALM_PART = 'psalm_part'
const PSALM_POSITION = 'psalm_position'
const PSALM_DURATION = 'psalm_duration'
const LOOP_TYPE = 'loop_type'
const LOOP_VOICES = 'loop_parts'

import mi from './mi.vue'
import TrackInfo from './TrackInfo'
import LoopOptions from './LoopOptions'

function zeroPad(n, p) {
  var pad = new Array(1 + p).join('0')
  return (pad + n).slice(-pad.length)
}

function contains(a, b) {
  do {
    if (a === b) return true
    b = b.parentNode
  } while (b)
  return false
}

export default {
  name: 'Player',
  data() {
    return {
      trackToFind: null,
      actuallyPlaying: false,
      storedPosition: null,
      listener: null,
      db,
      player,
      user,
      hls: null,
      durationSupported: false,
    }
  },
  components: {
    TrackInfo,
    LoopOptions,
    mi,
  },
  created() {

    // Add click-outside hander.
    this.listener = (e) => {
      if (!timeout && !contains(this.$refs.outer, e.target)) {
        let list = document.getElementById('psalmsList')
        if (!(list && contains(list, e.target))) {
          this.player.expand = false
        }
      }
      return true
    }
    document.addEventListener('click', this.listener)

    // Seek to desired position.
    this.player.position = parseFloat(localStorage.getItem(PSALM_POSITION) || '0')
    this.player.duration = parseFloat(localStorage.getItem(PSALM_DURATION) || '0')
    this.findTrack()

    let ttf = this.$route.query.track
    if (ttf) {

      // Jump to the track specified in the query string.
      this.trackToFind = {
        no: ttf.no,
        letter: ttf.letter,
        part: ttf.part,
        position: 0,
        duration: 0,
      }

      // Delete the query string from the browser address bar.
      // We don't want people bookmarking the wrong URL by mistake.
      this.$router.push({query:{}})

    } else {

      // Otherwise, look for the "last played track" in local storage.
      ttf = {
        no: localStorage.getItem(PSALM_NO),
        letter: localStorage.getItem(PSALM_LETTER) || '',
        part: localStorage.getItem(PSALM_PART) || 'normal',
        position: parseFloat(localStorage.getItem(PSALM_POSITION) || '0'),
        duration: parseFloat(localStorage.getItem(PSALM_DURATION) || '0'),
      }

      if (ttf.no) {

        // Track found in local storage!
        ttf.no = parseInt(ttf.no, 10)
        this.trackToFind = ttf

      }
    }

    // Load saved loop settings.
    let loopType = localStorage.getItem(LOOP_TYPE)
    if (loopType) this.player.loop.type = loopType
    let vv = localStorage.getItem(LOOP_VOICES)
    if (vv) this.player.loop.voices = JSON.parse(vv)

  },
  mounted() {
    this.updateHeight()
  },
  destroyed() {

    // Remove click-outside handler.
    document.removeEventListener('click', this.listener)

  },
  computed: {
    progress() {
      return (100 * this.player.position / this.player.duration) + '%'
    },
    track() {
      if (this.player.track >= 0 && this.player.track < this.db.length)
        return this.db[player.track]
      return null
    },
    sourceUrl() {
      let t = this.track, p = this.player.part
      if (!t) return null
      let pre = t.no < 10 ? '00' : t.no < 100 ? '0' : ''
      if (p === 'ncrpc') p = `${p}-${t.ncrpc}`
      return `https://trunk.newcreationrpc.org/hls/data/${pre}${t.no}${t.letter}-${p}-161k.m3u8`
    },
    useHls() { return Hls.isSupported() },
  },
  methods: {
    //dbg() { console.re.log(...arguments) },
    dbg() { },
    findTrack() {
      if (this.trackToFind === null)
        return
      let ttf = this.trackToFind
      this.dbg(`position: ${ttf.no}${ttf.letter}-${ttf.part} ${localStorage.getItem(PSALM_POSITION)} / ${localStorage.getItem(PSALM_DURATION)}`)
      for (let i=0; i<this.db.length; i++) {
        const t = this.db[i]
        if (t.no === ttf.no && t.letter === ttf.letter) {
          this.player.seekPosition = ttf.position
          this.player.track = i
          this.player.part = ttf.part
          this.player.position = ttf.position
          this.player.duration = ttf.duration
          this.trackToFind = null
        }
      }
    },
    loadMedia() {

      // Clear media.
      if (this.useHls) {
        if (this.hls) {
          this.hls.destroy()
          this.hls = null
        } else {
          this.$refs.source.src = ''
        }
      }

      if (!this.$refs.audio.paused)
        this.$refs.audio.pause()

      if (!this.sourceUrl)
        return

      if (this.useHls) {
        this.hls = new Hls({
          debug: false,
          xhrSetup: function(xhr, url) {
            xhr.withCredentials = true
          },
        })
        this.hls.on(Hls.Events.ERROR, (e,data) => { this.hError(data) })
        this.hls.attachMedia(this.$refs.audio)
        this.hls.loadSource(this.sourceUrl)
      } else {
        this.$refs.source.src = this.sourceUrl
      }
      this.$refs.audio.load()

    },
    play() {
      this.player.expand = 'info'
      this.player.playRequested = true
      this.$refs.audio.play()
      noSleep.enable()
    },
    pause() {
      this.player.playRequested = false
      this.$refs.audio.pause()
    },
    reload() {
      this.player.seekPosition = this.player.position
      this.loadMedia()
    },
    stop() {
      this.player.playRequested = false
      this.$refs.audio.pause()
      this.seek(0)
    },
    seek(s) {
      this.dbg(`seek: ${this.timeToString(s)}`)
      this.player.position = s
      this.player.seekPosition = s
      this.$refs.audio.currentTime = s
    },
    showPlaylist() {
      this.$router.push('/psalms')
      this.player.expand = false
    },
    sliderClick(e) {
      if (this.player.duration) {
        let r = e.target.getBoundingClientRect()
        this.seek((e.clientX - r.left) / r.width * this.player.duration)
      }
    },
    timeToString(s) {
      if (!s) s = 0
      return Math.floor(s / 60) + ':' + Math.floor(s % 60).toString().padStart(2, '0')
    },
    updateHeight() {
      player.height = this.$refs.outer.offsetHeight
    },

    aAbort() { this.dbg('abort') },
    aCanPlay() {
      this.dbg('canplay')
      let a = this.$refs.audio
      if (this.player.playRequested && a.paused) {
        a.play()
      }
    },
    aCanPlayThrough() { this.dbg('canplaythrough') },
    aDurationChange() {
      let a = this.$refs.audio, t = a.currentTime, d = a.duration
      this.dbg(`durationchange ${t} / ${d}`)
      if (!(d > 0 && isFinite(d))) return
      this.player.duration = d
    },
    aEmptied() {
      this.dbg('emptied')
      this.actuallyPlaying = false
    },
    aEnded() {
      this.dbg('ended')
      this.actuallyPlaying = false
      this.player.seekPosition = null
      this.player.playRequested = true

      let i

      // Should we autoplay a different voice for the current selection?
      for (i=parts.indexOf(this.player.part)+1; i<parts.length; i++) {
        if (this.player.loop.voices.indexOf(parts[i]) >= 0
            && ((parts[i] !== 'ncrpc' && this.db[this.player.track].published)
             || (parts[i] === 'ncrpc' && this.db[this.player.track].ncrpc_published))) {
          // Yes. Seek to beginning and select other voice. Will autoplay.
          this.player.seekPosition = 0
          this.player.position = 0
          this.player.part = parts[i]
          this.player.expand = 'info'
          return
        }
      }

      // Find the next track to autoplay:
      let nextTrack, lastTrack = this.player.track
      do {
        switch (this.player.loop.type) {
        case 'all':
          // Loop all selections in the Psalter.
          nextTrack = (lastTrack + 1) % this.db.length
          break
        case 'psalm':
          // Loop all selections within the current Psalm.
          nextTrack = (lastTrack + 1) % this.db.length
          if (this.db[nextTrack].no !== this.db[lastTrack].no) {
            for (i=lastTrack - 1; i >= 0 && this.db[i].no === this.db[lastTrack].no; i--)
              ;
            nextTrack = i + 1
          }
          break
        case 'selection':
          // Loop the current selection only.
          nextTrack = lastTrack
          break
        default:
          // Don't loop at all. Simply stop and return to beginning. Will not autoplay.
          this.player.playRequested = false
          this.seek(0)
          return
        }
        lastTrack = nextTrack
      } while (this.player.loop.voices.length
               && !((this.player.loop.voices[0] !== 'ncrpc' && this.db[nextTrack].published)
                    || (this.player.loop.voices.indexOf('ncrpc') >= 0 && this.db[nextTrack].ncrpc_published)))

      if (nextTrack !== this.player.track) {
        // Different track.
        this.player.part = (this.player.loop.voices.length
          ? (this.player.loop.voices[0] !== 'ncrpc'
            ? (this.db[nextTrack].published
              ? this.player.loop.voices[0]
              : 'ncrpc')
            : (this.db[nextTrack].ncrpc_published
              ? this.player.loop.voices[0]
              : 'normal'))
          : (this.db[nextTrack].published
            ? 'normal'
            : 'ncrpc'))
        this.player.track = nextTrack
        this.player.seekPosition = 0
        this.player.position = 0
        this.player.expand = 'info'
      } else if (this.player.loop.voices.length && this.player.part !== this.player.loop.voices[0]) {
        // Same track, different voice.
        this.player.seekPosition = 0
        this.player.position = 0
        this.player.part = this.player.loop.voices[0]
        this.player.expand = 'info'
      } else {
        // Same track, same voice.
        this.seek(0)
      }


    },
    aError() { this.dbg('error') },
    sError() {
      this.dbg('source error')
      this.reload()
    },
    hError(d) {
      this.dbg(`hls error. fatal ? ${d.fatal}`)
      if (d.fatal) {
        this.reload()
      }
    },
    aInterruptBegin() { this.dbg('interruptbegin') },
    aInterruptEnd() { this.dbg('interruptend') },
    aLoadedData() { this.dbg('loadeddata') },
    aLoadedMetadata() {
      this.dbg('loadedmetadata')
      let a = this.$refs.audio
      if (this.player.seekPosition !== null) {
        a.currentTime = this.player.seekPosition
      } else if (this.player.playRequested && a.paused) {
        this.$refs.audio.play()
      }
    },
    aLoadStart() { this.dbg('loadstart') },
    aPause() {
      this.dbg('pause')
      this.actuallyPlaying = false
      this.player.playRequested = false
    },
    aPlay() {
      this.dbg('play')
      this.player.playRequested = true
      const uID = this.user.id || 'anonymous'
      const t = this.track
      if (window.gtag)
        gtag('event', 'play-' + uID, {
          event_category: 'Psalm',
          event_label: zeroPad(t.no, 3) + t.letter + '-' + this.player.part,
        })
    },
    aPlaying() {
      this.dbg('playing')
      this.actuallyPlaying = true
    },
    aProgress() { this.dbg('progress') },
    aRateChange() { this.dbg('ratechange') },
    aSeeked() {
      this.dbg('seeked')
      this.player.position = this.$refs.audio.currentTime
      this.player.seekPosition = null
    },
    aSeeking() {
      this.dbg('seeking')
      this.actuallyPlaying = false
    },
    aStalled() { this.dbg('stalled') },
    aSuspend() { this.dbg('suspend') },
    aTimeUpdate() {
      let a = this.$refs.audio, t = a.currentTime, d = a.duration
      this.dbg(`timeupdate ${t} / ${d}`)
      if (this.player.playRequested && this.actuallyPlaying && t == 0 && d == 0 && !this.durationSupported) {
        this.dbg('Special case detected: playback ended on old browser')
        this.aEnded()
        return
      }
      if (this.player.seekPosition === null)
        this.player.position = t
      if (t === this.player.seekPosition)
        this.player.seekPosition = null
      if (!(d > 0 && isFinite(d))) return
      this.player.duration = d
    },
    aVolumeChange() { this.dbg('volumechange') },
    aWaiting() {
      this.dbg('waiting')
      this.actuallyPlaying = false
    },

  },
  watch: {
    db: function() {
      this.findTrack()
    },
    'player.playRequested': function(nv, ov) {
      if (nv && this.$refs.audio.paused) {
        this.$refs.audio.play()
      }
    },
    'player.expand': function(nv, ov) {
      this.$nextTick(() => {
        this.updateHeight()
      })
    },
    'player.loop.type': function(nv, ov) {
      localStorage.setItem(LOOP_TYPE, nv)
    },
    'player.loop.voices': function(nv, ov) {
      localStorage.setItem(LOOP_VOICES, JSON.stringify(nv))
    },
    'player.track': function(nv,ov) {
      this.dbg(`track ${ov} => ${nv}`)
      localStorage.setItem(PSALM_NO, this.db[nv].no)
      localStorage.setItem(PSALM_LETTER, this.db[nv].letter)
      if (this.player.part !== 'ncrpc') {
        if (!this.db[nv].published)
          this.player.part = 'ncrpc'
      } else if (this.player.part === 'ncrpc') {
        if (!this.db[nv].ncrpc_published)
          this.player.part = 'normal'
      }
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => { timeout = null }, 100)
    },
    'player.part': function(nv,ov) {
      this.dbg(`part ${ov} => ${nv}`)
      localStorage.setItem(PSALM_PART, nv)
      if (this.player.seekPosition === null && nv !== 'ncrpc' && ov !== 'ncrpc') {
        this.player.seekPosition = this.$refs.audio.currentTime
      }
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => { timeout = null }, 100)
    },
    'player.position': function(nv, ov) {
      nv = Math.floor(nv)
      if (nv !== Math.floor(this.storedPosition)) {
        localStorage.setItem(PSALM_POSITION, nv.toString())
        this.storedPosition = nv
      }
    },
    'player.duration': function(nv, ov) {
      if (nv > 0)
        this.durationSupported = true
      localStorage.setItem(PSALM_DURATION, nv.toString())
    },
    sourceUrl() { this.loadMedia() },
  },
}
</script>

<style lang="scss" scoped>

  @import './scss/vars.scss';
  $ec: #aaa;

  .outer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;

    box-shadow: 0 0 1rem 0 #888;
    font-family: $sans;
    border-top: 2px solid #000;
    z-index: 5;
    .expand {
      border-bottom: 2px solid #000;
      background: #fff;
    }
  }

  @media (min-width: $sidebarThreshold) {
    .expand { display: none; }
    .sidehide { display: none !important; }
  }

  .player {
    display: flex;
    flex-wrap: wrap;
    background: #888;
    @include user-select;

    .b {
      display: inline-block;
      box-sizing: border-box;
      background: #888;
      min-width: $size; max-width: $size;
      min-height: $size; min-height: $size;
      position: relative;
      cursor: default;

      $sc: #888;
      $sw: 30%;
      &.selected {
        > div {
          position: absolute;
          left: 0;
          right: 0;
          top: -2.01px;
          border-top: 2px dashed #000;
          bottom: 0;
          background: #fff;
          z-index: 1;
          background-image: linear-gradient(to right, $sc 0%, #fff $sw, #fff 100% - $sw, $sc 100%);
        }
      }

      > svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
      }

    }

    .s {
      flex-grow: 1;
      position: relative;
      min-width: 200px;
      background: #dfd;

      .p {
        position: absolute;
        left: 0; right:0; top:0; bottom:0;
        background: #7f7;
        width: 0%;
      }

      .t {
        position: relative;
        box-sizing: border-box;
        line-height: $size;
        font-size: $size*.45;
        padding-left: $size/3;
        overflow-x: visible;
        white-space: nowrap;
      }
    }

  }

</style>
