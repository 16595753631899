<template><div>
  <h1 v-if="user.isAnonymous">Login</h1>
  <div v-if="user.errorMessage" v-html="user.errorMessage" class="error"/>

  <div v-if="user.isBusy">
    Connecting. Please wait.
  </div>
  <div v-else-if="user.fetchRetry && false">
    <button @click="retry">Retry</button>
  </div>
  <div v-else-if="user.isAnonymous">
    <label><span>Username:</span>
      <input name="username" type="text" size="20" ref="username" v-model="username" /></label>
    <label><span>Password:</span>
      <input name="password" type="password" size="20" ref="password'" v-model="password" /></label>
    <button @click="login">Login</button>
  </div>
  <div v-else>
    <label>You are logged in as <em v-html="user.first_name" />.</label>
    <button @click="logout">Logout</button>
  </div>
</div></template>

<script>
import { user } from './db'
export default {
  name: 'Account',
  data() {
    return {
      user,
      username: '',
      password: '',
    }
  },
  methods: {
    retry() {
      this.user.fetch()
    },
    login() {
      this.user.login(this.username, this.password)
    },
    logout() {
      this.user.logout()
    },
  },
}
</script>

<style lang="scss" scoped>

label {
  display: block;
  margin: 10px 0;
  span {
    display: inline-block;
    width: 110px;
  }
  input {
    vertical-align: middle;
  }
}
.error {
  font-weight: bold;
  color: #c00;
}

</style>
