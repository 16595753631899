<template><div>

	<p>Sermon livestreams will be available at 9:00 a.m. and 4:00 p.m. on Sunday.</p>

	<p>If the video below doesn't work,
	<a href="https://www.youtube.com/user/A1373S9865/videos?view_as=subscriber">click here to
	Pastor Wilkinson's Youtube channel</a> and look for the live stream.</p>

	<div class="ncrpcvideo"><iframe width="100%" height="100%"
	src="https://www.youtube.com/embed/live_stream?channel=UC50ZJlu47aYZ6x_VMKhTqZw"
	frameborder="0" allowfullscreen></iframe></div>

	<p>.</p>

</div></template>
<script>
export default {
  name: 'Live',
  data() {
    return {
      mode: 'scott',
    }
  },
}
</script>
<style lang="scss">
.ncrpcvideo {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
}
</style>
