function keyval(qs, key) {
  var vars = qs.split('&')
  for (var i=0; i<vars.length; i++) {
    var pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) == key) {
      return decodeURIComponent(pair[1])
    }
  }
  return null
}

export function parseQuery(qs) {
  let m

  // examples: ?78Etenor, ?80bass, ?95A
  m = qs.match(/^([0-9]+)([A-Z]?)([a-z]*)(&.*)?$/)
  if (m !== null) {
    let track = {
      no: parseInt(m[1], 10),
      letter: m[2],
    }
    if (!m[3]) track.part = 'normal'
    else {
      switch (m[3][0]) {
      default:  track.part = 'normal'; break
      case 's': track.part = 'soprano'; break
      case 'a': track.part = 'alto'; break
      case 't': track.part = 'tenor'; break
      case 'b': track.part = 'bass'; break
      case 'c': track.part = 'ncrpc'; break
      }
    }
    return { track, }
  }

  // Check old format: ?s=78E&p=bass
  let s = keyval(qs, 's')
  if (s) {
    m = s.match(/^([0-9]+)([A-Z]?)(&.*)?$/)
    if (m !== null) {
      let track = {
        no: parseInt(m[1], 10),
        letter: m[2],
      }
      let p = keyval(qs, 'p')
      if (!p) track.part = 'normal'
      else {
        switch (p[0].toLowerCase()) {
        default:  track.part = 'normal'; break
        case 's': track.part = 'soprano'; break
        case 'a': track.part = 'alto'; break
        case 't': track.part = 'tenor'; break
        case 'b': track.part = 'bass'; break
        }
      }
      return { track, }
    }

  }

  return {}
}

export function stringifyQuery(o) {
  if (o.track) {
    let part = o.track.part === 'normal' ? '' : o.track.part
    return `?${o.track.no}${o.track.letter}${part}`
  }
  return ''
}
