<template><div class="loop">
  <a v-if="closeable" class="close" @click="player.expand=false"><mi id="close" /></a>
  <div v-if="title" class="title">Loop Settings</div>
  <div class="group" v-for="o in loopOptions" :key="`group-${o.key}`">
    <span v-html="`${o.label}:`"/>
    <label v-for="v in o.values"
          :key="`group-${o.key}-${v[0]}`"
          @click="clickLoopOption(o,v)">
      <mi :id="o.multiple ? (player.loop[o.key].indexOf(v[0]) >= 0 ? 'check_box' : 'check_box_outline_blank') : (player.loop[o.key] === v[0] ? 'radio_button_checked' : 'radio_button_unchecked')"
        /><span v-html="v[1]"/>
    </label>
  </div>
</div></template>

<script>
import { player } from './db.js'
import mi from './mi.vue'
export default {
  name: 'LoopOptions',
  props: {
    title: { type: Boolean, default: true },
    closeable: { type: Boolean, default: false },
  },
  components: {
    mi,
  },
  data() {
    return {
      loopOptions: [
        {
          key: 'voices',
          label: 'I want to hear these version(s)',
          multiple: true,
          values: [['normal','Ensemble'],['soprano','Soprano'],['alto','Alto'],['tenor','Tenor'],['bass','Bass'],['ncrpc','Congregation']],
        },
        {
          key: 'type',
          label: 'Loop Type',
          multiple: false,
          values: [['all','All Selections'],['psalm','All Selections in Current Psalm'],['selection','Current Selection Only'],['none','No loop']],
        },
      ],
      player,
    }
  },
  methods: {
    clickLoopOption(o,v) {
      let vv = o.values.map(v => v[0])
      v = v[0]
      if (o.multiple) {
        let L = this.player.loop[o.key], i = L.indexOf(v)
        if (i >= 0) L.splice(i, 1)
        else {
          i = vv.indexOf(v)
          let j=0,k=0
          for (let j=0,k=0; j<L.length && k >= 0; j++) {
            k = vv.indexOf(L[j], k)
            if (k >= i) {
              L.splice(j, 0, v)
              return
            }
          }
          L.push(v)
        }
      } else {
        this.player.loop[o.key] = v
      }
    },
  },
}
</script>

<style lang="scss">
@import './scss/vars.scss';
.loop {
  @include user-select;
  padding: 20px;
  .close {
    display: block;
    float: right;
    margin: -20px;
    padding: 20px;
    cursor: pointer;
    svg {
      fill: #000;
      vertical-align: top;
    }
    &:hover svg {
      filter: drop-shadow(3px 3px 2px rgba(0,0,0,.7));
    }
  }
  .title { margin-bottom: 10px; font-weight: bold; }
  .group {
    margin: 0 0 10px 20px;
    text-indent: -20px;
    label {
      text-indent: 0px;
      display: inline-block;
      padding-right: 10px;
      white-space: nowrap;
      line-height: 40px;
      .mi { padding-right: 2px; }
    }
  }
  .warning { font-style: italic; }
  .mi { vertical-align: sub; }
}

@mixin progressive($media, $size) {
  $ratio: $size / 19px;
  @media (max-width: $media) {
    .loop {
      font-size: $size;
      padding: 20px * $ratio;
      .close { margin: -20px * $ratio; padding: 20px * $ratio; }
      .title { margin-bottom: 10px * $ratio; }
      .group {
        margin: 0 0 10px * $ratio 20px * $ratio;
        text-indent: -20px * $ratio;
        label {
          padding-right: 10px * $ratio;
          line-height: 40px * $ratio;
          .mi {
            padding-right: 2px * $ratio;
            width: 24px * $ratio;
            height: 24px * $ratio;
          }
        }
      }
    }
  }
}
@include progressive(400px, 14px);
@include progressive(300px, 11px);
@include progressive(432px, 4.4vw);
</style>
