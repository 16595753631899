<template><div class="outer">

  <table id="psalmsList">
    <tr v-for="(p,i) in db" :key="'track-'+i" @click.prevent="clickTrack(i)" :class="{active:active(i)}" ref="tt">
      <td class="arrow"><span :class="{'hide':player.track!==i}"><mi size="18px" id="play_arrow" /></span></td>
      <td class="no" :class="{active:player.track===i}">{{p.no}}{{p.letter}}</td>
      <td class="name" :class="{active:active(i)}">Psalm {{p.no}}<span v-if="p.verses">:{{p.verses}}</span></td>
      <template v-if="p.published">
        <td class="part" :class="{active:active(i,'normal')}" @click.prevent="player.part='normal'">
          <mi size="24px" id="people" />Ensemble
        <td class="part" :class="{active:active(i,'soprano')}" @click.prevent="player.part='soprano'">Soprano</td>
        <td class="part" :class="{active:active(i,'alto')}" @click.prevent="player.part='alto'">Alto</td>
        <td class="part" :class="{active:active(i,'tenor')}" @click.prevent="player.part='tenor'">Tenor</td>
        <td class="part" :class="{active:active(i,'bass')}" @click.prevent="player.part='bass'">Bass</td>
      </template>
      <template v-else>
        <td class="part" />
        <td class="part" />
        <td class="part" />
        <td class="part" />
        <td class="part" />
      </template>
      <td v-if="p.ncrpc_published" class="part" :class="{active:active(i,'ncrpc')}" @click.prevent="player.part='ncrpc'">
        <img src="./i/church.png" />Congregation
      </td>
      <td v-else class="part">
      <td class="fill"><div/></td>
    </tr>
  </table>

</div></template>
<script>

import { db, player } from './db'
import mi from './mi'

export default {
  name: 'Psalms',
  components: {
    mi,
  },
  mounted() {
    this.$nextTick(() => { this.updateScroll() })
  },
  data() {
    return {
      db: db,
      player: player,
      clicking: false,
    }
  },
  methods: {
    active(track, part) {
      return (this.player.track === track) && (!part || this.player.part === part)
    },
    clickTrack(track) {
      this.clicking = true
      this.player.playRequested = true
      this.player.expand = 'info'
      if (this.player.track !== track) {
        this.player.position = 0
        this.player.seekPosition = 0
      }
      this.player.track = track
      setTimeout(() => { this.clicking = false })
    },
    updateScroll() {
      this.$nextTick(() => {
        if (!this.$refs.tt) return // no track rows have been created
        let main = document.getElementsByTagName('html')[0],
          el = this.$refs.tt[this.player.track],
          elTop = 0
        for (let tmp=el; tmp&&tmp!==main; tmp=tmp.offsetParent)
          elTop += tmp.offsetTop
        if (main.scrollTop > elTop) {
          main.scrollTop = elTop
        } else {
          let minTop = elTop + el.clientHeight - main.clientHeight + player.height
          if (main.scrollTop < minTop) {
            if (this.clicking)
              main.scrollTop = minTop
            else {
              minTop = Math.max(
                elTop + 3.5 * el.clientHeight - main.clientHeight + player.height,
                elTop + (el.clientHeight - main.clientHeight + player.height) / 3
              )
              if (minTop < elTop)
                main.scrollTop = minTop
              else
                main.scrollTop = elTop
            }
          }
        }
      })
    },
  },
  watch: {
    'player.expand': function(nv) {
      if (nv) {
        this.$nextTick(() => {
          this.updateScroll()
        })
      }
    },
    'player.playRequested': function(nv) {
      if (nv) {
        this.$nextTick(() => {
          this.updateScroll()
        })
      }
    },
    'player.track': function() {
      this.$nextTick(() => {
        this.updateScroll()
      })
    },
    'player.part': function() {
      this.$nextTick(() => {
        this.updateScroll()
      })
    },
  },
}
</script>
<style lang="scss" scoped>

@import './scss/vars.scss';

.outer {
  margin: 0 -15px;
}

table {
  border-collapse: collapse;
  font-family: $sans;
  @include user-select;
  width: 100%;
}

tr {
  cursor: pointer;
  &:nth-child(even) { background: #eee; }
  &.active { background: #acf; }
}

td {
  padding: 10px 0;

  &.arrow { width: 1%; }
  &.no { padding: 10px; width: 1%; }
  &.part {
    padding: 10px;
    text-align: center;
    img {
      width: 20px;
    }
  }
  &.name {
    white-space: nowrap;
    width: 100%;
    padding-right: 10px;
  }
  &.fill div { width: 10px; }

  &.active {
    &.part { background: #def; font-style: italic; }
    &:not(.part) { font-weight: 700; }
  }
}

.mi { vertical-align: middle; }

.hide { fill: transparent; }

@media (max-width: 500px) { .no { display: none; } }
@media (max-width: 400px) { td.no { display: table-cell; padding: 10px 0; } td.name { display: none; } td.part { padding: 10px 0; } }

td.part {
  .mi, img { display: none; }
}
@media (max-width: 790px) {
  td.part {
    font-size: 0;
    .mi, img { display: inline-block; }
  }
  td.part::first-letter {
    font-size: 18px;
  }
}

</style>
