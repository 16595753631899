import Vue from 'vue'
import Router from 'vue-router'
import Index from './Index'
import Beliefs from './Beliefs'
import Leadership from './Leadership'
import Location from './Location'
import Sermons from './Sermons'
import Psalms from './Psalms'
import Links from './Links'
import Live from './Live'
import Contact from './Contact'
import Account from './Account'
import { parseQuery, stringifyQuery } from './qs.js'

Vue.use(Router)

export let routes = [
  { path: '/',           component: Index, name: 'Home' },
  { path: '/beliefs',    component: Beliefs },
  { path: '/leadership', component: Leadership },
  { path: '/location',   component: Location, name: 'Times &amp; Location' },
  { path: '/live',       component: Live, name: 'Live Stream' },
  { path: '/sermons',    component: Sermons },
  { path: '/psalms',     component: Psalms },
  { path: '/links',      component: Links },
  { path: '/contact',    component: Contact },
  { path: '/account',    component: Account, navbar: false }
]

export default new Router({
  mode: 'history',
  routes: routes,
  parseQuery,
  stringifyQuery,
})
