<template><div class="track">
  <template v-if="track">
    <a v-if="closeable" class="close" @click="player.expand=false"><mi id="close" /></a>
    <div class="no">{{track.no}}{{track.letter}}</div>
    <div class="vv">Psalm {{track.no}}<span v-if="track.verses">:{{track.verses}}</span></div>
    <div class="part" v-if="player.part !== 'normal'">&mdash; {{ part }}</div>
    <div class="permalink">
      <a :href="linkUrl" @click.prevent="copyLink" :title="linkUrl">
        <mi id="link" /> Copy Link
      </a>
    </div>
    <div class="title" v-html="track.title"/>
    <div class="composer" v-html="track.composer"/>
    <div class="tune"><span v-html="track.tune"/> <span v-html="track.meter"/></div>
    <div class="copyright">
  		From <i><a href="http://www.crownandcovenant.com/The_Book_of_Psalms_for_Worship_p/cm101.htm" target="_blank">The Book of Psalms for Worship</a></i>
  		&copy; 2009 <a href="http://www.crownandcovenant.com/default.asp" target="_blank">Crown &amp; Covenant Publications</a>.
    </div>
  </template>
  <i v-else>
    Loading...
  </i>
</div></template>

<script>
import { db, player } from './db.js'
import mi from './mi'
import copy from 'copy-to-clipboard'
import { stringifyQuery } from './qs.js'
export default {
  name: 'TrackInfo',
  props: {
    closeable: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    mi,
  },
  computed: {
    linkUrl() {
      if (this.track === null)
        return null
      let url = window.location.href.match(/^([^?#]+)/)[0],
        t = this.track,
        p = player.part === 'ncrpc' ? 'congregation' : player.part
      return url + stringifyQuery({ track: { no:t.no, letter:t.letter, part:p }})
    },
    part() {
      if (this.player.part === 'ncrpc')
        return 'Congregation'
      return this.player.part[0].toUpperCase() + this.player.part.substring(1)
    },
    track() {
      if (this.player.track >= 0 && this.player.track < this.db.length)
        return this.db[player.track]
      return null
    },
  },
  data() {
    return {
      db,
      player,
    }
  },
  methods: {
    copyLink() {
      if (copy(this.linkUrl)) {
        alert('Copied link to clipboard!')
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.track {

  padding: 20px;

  .close {
    display: block;
    float: right;
    margin: -20px;
    padding: 20px;
    cursor: pointer;
    svg {
      fill: #000;
      vertical-align: top;
    }
    &:hover svg {
      filter: drop-shadow(3px 3px 2px rgba(0,0,0,.7));
    }
  }

  .no {
    font-size: 150%;
    font-weight: bold;
    display: inline;
  }

  .vv {
    padding-left: 0.25em;
    display: inline;
  }

  .part {
    display: inline;
    font-style: italic;
  }

  .title {
    font-weight: bold;
    margin: 0.5rem 0 0 0;
  }

  .composer {
    margin: 0 0 0.25rem 0;
  }

  .tune {
    text-transform: uppercase;
  }

  .copyright {
    margin-top: 0.5rem;
  }


  .permalink {
    font-size: 84%;
    display: inline;
    a {
      white-space: nowrap;
      display: inline-block;
      text-decoration: none;
      line-height: 0;
      border-bottom: 1px solid #3366FF;
      .mi {
        vertical-align: text-bottom;
        width: 19px;
        height: 19px;
      }
    }
  }
}

@mixin progressive($mw, $mh, $size) {
  @media (max-width: $mw) and (max-height: $mh) {
    .track {
      padding: $size;
      font-size: $size;
      .close { margin: -$size; padding: $size; }
      .permalink a .mi { width: $size; height: $size; }
    }
  }
}
@include progressive(400px, 200vh, 13px);
@include progressive(300px, 200vh, 9px);
@include progressive(452px, 200vh, 4.2vw);

@media (max-height: 450px) {
  @include progressive(200vw, 400px, 15px)
  @include progressive(200vw, 300px, 12px)
  @include progressive(200vw, 250px, 10px)
  @include progressive(200vw, 450px, 4.222vh);
}

</style>
